document.addEventListener("alpine:init", () => {
    Alpine.data("makeService", (obj) => ({
        clinicId: null,
        showDepartments: false,
        showSpecialties: false,
        showDoctors: false,
        specialties: [],
        doctors: [],

        /**
         *  Получить все специальности по id отделения
         */
        filterSpecialties(option) {
            this.showSpecialties = false;
            this.showDoctors = false;

            if (option) {
                fetch(`/api/specialties?clinic_id=${this.clinicId}&department_id=${option}`)
                    .then(response => response.json())
                    .then(result => {
                        this.specialties = result.data;
                        if (this.specialties) {
                            this.showSpecialties = true;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        },
        /**
         *  Получить всех докторов по id специальности
         */
        filterDoctors(option) {
            this.showDoctors = false;

            if (option) {
                fetch(`/appointment/doctors?clinic_id=${this.clinicId}&specialty_id=${option}`)
                    .then(response => response.json())
                    .then(result => {
                        this.doctors = result.data;

                        if (this.doctors.length > 0) {
                            this.showDoctors = true;
                        }

                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                this.showDoctors = false;
            }
        },
        setClinicId(clinicId) {
            this.clinicId = clinicId;
            this.showDepartments = true;
        },
        getSpecialties() {
            return this.specialties;
        },
        getDoctors() {
            return this.doctors;
        }
    }));
});
