import '../app.js';
import Alpine from "alpinejs";
window.Alpine = Alpine;
import './filterBySpecialty';
import axios from "axios";
import {runInContext as CKEDITOR} from "lodash";

ClassicEditor
    .create( document.querySelector( '#editor' ), {
        ckfinder: {
            uploadUrl: '/api/ckeditor/images?temp_media_uuid=' + document.getElementById('temp_media_uuid')?.value
        }
    })
    .catch( error => {
        console.error( error );
    } )
// CKEDITOR.replace('editor', {
//     allowedContent: {
//         $1: {
//             // Использование универсального селектора ($1) для всех элементов
//             styles: true,
//             attributes: true,
//             classes: true
//         }
//     }
// });
Alpine.store('media', {
    delete(id) {
        return axios
            .delete('/admin/media/' + id)
            .then(r => {})
    }
});
Alpine.start();
